body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* for removing the highlight */
  -webkit-tap-highlight-color: transparent;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* .map-container {
height: 400px;
} */

.mapboxgl-user-location-dot {
  visibility: hidden;
}

.mapboxgl-user-location-heading:before,
.mapboxgl-user-location-heading:after {
  border-bottom: 7.5px solid #fcbc59 !important;
}

.user-marker {
  /* background-image: url('https://docs.mapbox.com/mapbox-gl-js/assets/pin.svg'); */
  background-image: url("/public/user-marker.png");
  background-size: cover;
  cursor: pointer;
}

.user-marker-label,
.event-invitation-marker-label {
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  white-space: nowrap;
  position: absolute;
  bottom: -24px;
  left: 50%;
  transform: translateX(-50%);
}

.new-event-marker {
  background-image: url("/public/new-event-marker.png");
  background-size: cover;
  cursor: pointer;
}

.event-invitation-marker {
  background-size: cover;
  cursor: pointer;
}

.ongoing-event-invitation-marker {
  background-image: url("/public/ongoing-event-invitation-marker.png");
}

.upcoming-event-invitation-marker {
  background-image: url("/public/upcoming-event-invitation-marker.png");
}

.sidebar {
  /* background-color: rgba(35, 55, 75, 0.9); */
  /* background-color: white; */
  color: #fff;
  padding: 6px 12px;
  font-family: monospace;
  z-index: 1;
  position: absolute;
  /* top: 0; */
  bottom: 6.5rem;
  /* left: 1rem; */
  margin-bottom: 12px;
  border-radius: 4px;
}

.MuiSlider-markLabel {
  /* color: white !important; */
  font-weight: bold !important;
}

.mapboxgl-ctrl-group:not(:first-child) {
  margin-bottom: 5rem !important;
}

.mapboxgl-ctrl-geocoder {
  border-radius: 25px !important;
}

@media (max-width: 900px) {
  .mapboxgl-ctrl-geocoder {
    transform: scale(0.75) !important;
    transform-origin: top left !important;
  }
}

/* @media (max-width: 900px) { */
.mapboxgl-ctrl-group {
  /* display: none !important; */
}

.mapboxgl-ctrl-geolocate,
.mapboxgl-ctrl-zoom-in,
.mapboxgl-ctrl-zoom-out,
.mapboxgl-ctrl-compass {
  display: none !important;
}

/* } */

.download-tooltip {
  margin-top: 0px !important;
}

.download-tooltip-popper {
  z-index: 1200 !important;
}

.mapboxgl-popup-content {
  background-color: rgba(255, 255, 255, 0.75) !important;
  padding: 0 !important;
  font-family: "Asap", sans-serif !important;
  border-radius: 10px !important;
}

.mapboxgl-popup-anchor-left {
  .mapboxgl-popup-tip {
    border-right-color: rgba(255, 255, 255, 0.75) !important;
  }
}

.mapboxgl-popup-anchor-top,
.mapboxgl-popup-anchor-bottom {
  .mapboxgl-popup-tip {
    border-bottom-color: rgba(255, 255, 255, 0.75) !important;
    border-top-color: rgba(255, 255, 255, 0.75) !important;
  }
}

.mapboxgl-popup-anchor-right {
  .mapboxgl-popup-tip {
    border-left-color: rgba(255, 255, 255, 0.75) !important;
  }
}

.mapboxgl-popup-anchor-left,
.mapboxgl-popup-anchor-right {
  .mapboxgl-popup-tip {
    border-bottom-color: rgba(255, 255, 255, 0) !important;
    border-top-color: rgba(255, 255, 255, 0) !important;
  }
}

.rdrDefinedRangesWrapper {
  border-right: 1px solid rgb(132, 144, 149) !important;
}

@media (max-width: 900px) {
  .rdrDateRangePickerWrapper {
    display: block !important;
  }

  .rdrDefinedRangesWrapper {
    width: 100% !important;
    height: auto;
  }

  .rdrDefinedRangesWrapper {
    border-right: none !important;
  }
}

.rdrInputRanges {
  display: none;
}

.rdrDay,
.rdrStaticRange,
.rdrDateDisplayWrapper,
.rdrMonthAndYearWrapper,
.rdrMonth,
.rdrDefinedRangesWrapper {
  color: white;
  background-color: #121212 !important;
  background-image: linear-gradient(rgba(255, 255, 255, 0.16), rgba(255, 255, 255, 0.16)) !important;
}

.rdrStaticRange {
  border-bottom: 1px solid rgb(132, 144, 149) !important;
}

.rdrStaticRange:hover {
  background-color: black !important;
}

.rdrStaticRange:hover .rdrStaticRangeLabel,
.rdrStaticRange:focus .rdrStaticRangeLabel {
  background-color: unset !important;
}

.rdrMonthPicker>select,
.rdrYearPicker>select,
.rdrDayNumber>span {
  color: white !important;
}

.rdrMonthPicker>select>option,
.rdrYearPicker>select>option {
  color: #000000;
}

.rdrDayDisabled>span>span {
  color: #aeb9bf !important;
}

.rdrDateDisplayItem input,
.rdrDateDisplayItemActive input {
  color: #000000 !important;
}

/* .rdrDayToday .rdrDayNumber span:after {
  background: #0097B2;
} */

.MuiAutocomplete-paper {
  background-color: rgba(18, 18, 18, 0.95) !important;
}

.MuiDrawer-root {
  top: 8vh !important;
}

.MuiDialog-paper {
  background-color: unset !important;
  background-image: linear-gradient(#171717, #191919) !important;
}

@media (max-width: 900px) {
  .MuiDialog-paper {
    box-shadow: unset !important;
  }
}

.w-100 {
  width: 100% !important;
}

/* password checklist */
li.valid,
li.invalid {
  align-items: center;
}

/* https://github.com/yudielcurbelo/react-qr-scanner/issues/74#issuecomment-2373444517 */
.scanner {
  aspect-ratio: 1;

  path {
    stroke: #0097B2;
  }

  video {
    object-fit: cover;
  }

  svg {
    max-width: 100%;
    max-height: 100%;
  }

}

.opaque-marker {
  opacity: 0.2 !important;
  pointer-events: none !important;
}